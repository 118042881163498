import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { styles } from "../globals"
import { StaticQuery, graphql } from "gatsby"

const GET_MAP = graphql`
  {
    getMap: file(relativePath: { eq: "google-maps.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default function Map() {
  return (
    <MapWrapper>
      <a
        href="https://goo.gl/maps/KoGniiTnBRU9ETRc7"
        rel="noopener noreferrer"
        target="_blank"
      >
        <StaticQuery
          query={GET_MAP}
          render={data => {
            return (
              <Img fluid={data.getMap.childImageSharp.fluid} className="map" />
            )
          }}
        />
      </a>
    </MapWrapper>
  )
}

const MapWrapper = styled.div`
  overflow: hidden;
  .map {
    transform: scale(1.5);
  }

  @media (min-width: ${styles.smallScreenBreakpoint}) {
    width: 70vw;
    margin: 0 auto;
    .map {
      transform: scale(1);
    }
  }
`
